
import { defineComponent, ref } from 'vue';

import { useInformations } from '@/composables/api';
import permissionUnits from '@/components/permission-units/index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const { data, isLoading, isFetching } = useInformations({ page });
    const { t } = useI18n();

    return { page, data, isLoading, t, isFetching };
  }
});
